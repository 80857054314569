<template>
    <div>
        <a :href="'tel:002'+phone"  target="_blank">
        <!--<a :href="!isMobile() ? 'tel:002'+phone : 'whatsapp://call?phone=002'+phone" target="_blank">-->
            <slot></slot>
        </a>
            
    </div>
</template>

<script>
export default {
    props: ['phone']
}
</script>